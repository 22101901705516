app-sales-progress-bar {
  .progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 10px;
    position: relative;

    .progress-bar {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #027cc2;
      transition: width 0.6s ease;
    }

    .progress-text {
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 0.75rem !important;
    }
  }
}
