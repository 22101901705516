app-sales-dashboard {
  .sales-stats-big {
    text-align: left;
    .header {
      text-transform: uppercase;
      font-weight: 500;
    }
    .percent {
      position: relative;
      .number {
        font-size: 64px;
        font-weight: 200;
        width: 90%;
      }
      .arrow {
        color: #689f38;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
    .progress-arrow {
    }
  }
}
