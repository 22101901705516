.notification-type-header {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  max-height: 100px;

  &.stacked {
    opacity: 0;
    max-height: 0;
  }
}

.notification-container {
  transition: all 0.5s ease-in-out;
  max-height: 99999px;
  &.stacked {
    max-height: 120px;
  }

  .notification {
    max-height: 99999px;
    transition: all 0.5s linear;
    cursor: pointer;
    overflow: hidden;

    &.stacked {
      opacity: 0;
      visibility: hidden;
      height: 80px;
      p {
        transition: all 0.5s ease-in-out;
      }
    }

    &:nth-of-type(1) {
      transition: all 0.5s ease-in-out;
      &.stacked {
        opacity: 1;
        visibility: visible;
      }
    }
    &:nth-of-type(2) {
      transition: all 0.5s ease-in-out;

      &.stacked {
        transform: translateY(-100%);
        opacity: 1;
        z-index: -1;
        visibility: visible;

        p {
          opacity: 0;
        }
      }
    }
    &:nth-of-type(3) {
      transition: all 0.5s ease-in-out;

      &.stacked {
        transform: translateY(-200%);
        opacity: 1;
        z-index: -2;
        visibility: visible;

        p {
          opacity: 0;
        }
      }
    }
  }
}

.insights-chart {
  height: 100%;
  width: 100%;
}

.insights-chart .ngx-charts {
  float: none !important;
}

.insights-big-chart {
  width: 100%;
  height: 400px;
}

.insights-small-chart {
  width: 100%;
  height: 50px;
}

.insights-intractable {
  cursor: pointer;
}
.insights-intractable:hover {
  background-color: #0000000f;
}

.insights-compare-button {
  top: 0;
  right: 0;
  position: absolute !important;
}

.insights-month-selector {
  right: $card-spacer-x;
  position: absolute !important;
}
