.fs-1-4 {
  font-size: 1.4em;
}

.fs-1-5 {
  font-size: 1.5em;
}

.bg-gray-200 {
  background-color: $gray-200 !important;
}

.bg-gray-300 {
  background-color: $gray-300 !important;
}

.bg-gray-400 {
  background-color: $gray-400 !important;
}

.bg-gray-500 {
  background-color: $gray-500 !important;
}

.bg-gray-600 {
  background-color: $gray-600 !important;
}

.bg-gray-700 {
  background-color: $gray-700 !important;
}

.bg-gray-800 {
  background-color: $gray-800 !important;
}

.bg-gray-900 {
  background-color: $gray-900 !important;
}
