app-guest-details {
    $guest-info-col-width: 400px;

    .guest-info-col {
        @include media-breakpoint-up(lg) {
            flex: 0 0 $guest-info-col-width;
            max-width: $guest-info-col-width;
        }
    }

    .guest-content-col {
        @include media-breakpoint-up(lg) {
            flex: 0 0 calc(100% - 400px);
            max-width: calc(100% - 400px);
        }
    }
}
