app-guest-field-definition-settings {
  .fields-container {
    background-color: #dee2e6;
    border-radius: 0.3rem;
    padding: map-get($spacers, 1);
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

    .mat-chip-remove {
      display: none;
    }
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .field-card {
    position: relative;

    form {
      min-height: 80px;
      align-items: baseline;
      padding-left: 25px;
    }

    .drag-handle {
      display: none;
      position: absolute;
      top: 5px;
      left: 5px;
      color: #fff;
      cursor: move;
      background-color: #ccccccb3;
      border-radius: 50%;
      padding: 5px;
      z-index: 1000;
    }

    &:hover {
      .drag-handle {
        display: block;
      }
    }
  }
}

.cdk-drag.guest-field-def-drag.cdk-drag-preview {
  .drag-handle {
    display: none;
  }
}
