// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core;

// define a real custom palette (using http://mcg.mbitson.com)

$md-dark: (
  50: #e6e7ea,
  100: #c0c4c9,
  200: #969ca6,
  300: #6c7482,
  400: #4d5767,
  500: #2d394c,
  600: #283345,
  700: #222c3c,
  800: #1c2433,
  900: #111724,
  A100: #6598ff,
  A200: #3276ff,
  A400: #0055fe,
  A700: #004ce5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$md-blue: (
  50: #e1eff8,
  100: #b3d8ed,
  200: #81bee1,
  300: #4ea3d4,
  400: #2890cb,
  500: #027cc2,
  600: #0274bc,
  700: #0169b4,
  800: #015fac,
  900: #014c9f,
  A100: #c9dfff,
  A200: #96c1ff,
  A400: #63a3ff,
  A700: #4a94ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat-palette($md-blue);
$accent: mat-palette($md-dark);
$background-color: map_get($mat-blue-grey, 50);

// The warn palette is optional (defaults to red).
$warn: mat-palette($mat-red, A700);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat-light-theme($primary, $accent, $warn);

// Insert custom background color
$background: map-get($theme, background);
$background: map_merge(
  $background,
  (
    background: $background-color
  )
);
$theme: map_merge(
  $theme,
  (
    background: $background
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);
// @include my-app-theme($theme);

@import '~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';
@include mat-datetimepicker-theme($theme);

@import '~saturn-datepicker/theming';
@include sat-datepicker-theme($theme); // material theme data structure https://material.angular.io/guide/theming#defining-a-custom-theme

$custom-typography: mat-typography-config(
  $font-family: 'Heebo'
);
@include mat-core($custom-typography);

.mat-toolbar {
  font-size: 0.9rem;
}

.bottomsheet-header {
  position: sticky;
  display: block;
  top: 0;
  background: $white;
  top: -8px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -8px;
  padding: 8px 16px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

mat-slide-toggle.custom-slider {
  .mat-slide-toggle-bar {
    width: 38px;
    height: 22px;
    border-radius: 20px;
    border: 1px solid #DFDFDF;
    background-color: rgba(255 ,255, 255, 0);

    .mat-slide-toggle-thumb-container {
      top: 0px;

      .mat-slide-toggle-thumb {
        background-color: #fff !important;
      }
    }
  }
}

mat-slide-toggle.custom-slider.mat-checked {
  .mat-slide-toggle-bar {
    background-color: #64bd63;
  }
}

