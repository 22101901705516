/* You can add global styles to this file, and also import other style files */

@import 'font';

@import '../../node_modules/bootstrap/scss/functions';
// custom bootstrap vars (need functions)
@import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import 'bs-variables';
// @import "../../node_modules/bootstrap/scss/bootstrap";

// Required Bootstrap
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
// Optional Bootstrap
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
// @import "../../node_modules/bootstrap/scss/buttons"; // substituted by material
@import '../../node_modules/bootstrap/scss/transitions';
// @import "../../node_modules/bootstrap/scss/dropdown"; // substituted by material
// @import "../../node_modules/bootstrap/scss/button-group";  // substituted by material
@import '../../node_modules/bootstrap/scss/input-group';
// @import "../../node_modules/bootstrap/scss/custom-forms"; // substituted by material
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge"; // substituted by material
// @import "../../node_modules/bootstrap/scss/jumbotron";
@import '../../node_modules/bootstrap/scss/alert';
// @import "../../node_modules/bootstrap/scss/progress"; // substituted by material
// @import "../../node_modules/bootstrap/scss/media";
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal"; // substituted by material
// @import "../../node_modules/bootstrap/scss/tooltip"; // substituted by material
// @import "../../node_modules/bootstrap/scss/popover";// substituted by material
// @import "../../node_modules/bootstrap/scss/carousel"; // substituted by material
// @import "../../node_modules/bootstrap/scss/spinners"; // substituted by material
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/print';

//import quill wysiwyg editor styles
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import 'spinner';
@import 'utils';
@import 'rtl';
@import 'responsive';
@import './variables.scss';

.pointer {
  cursor: pointer;
}

.gradient {
  background: $topnav-bg; /* Old browsers */
  background: -moz-linear-gradient(top, $topnav-bg 0%, $sidebar-bg 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $topnav-bg 0%, $sidebar-bg 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $topnav-bg 0%, $sidebar-bg 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$topnav-bg', endColorstr='$sidebar-bg', GradientType=0); /* IE6-9 */
}

.loading-screen {
  height: 100vh;
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after {
  display: none;
}

.pointer {
  cursor: pointer;
}

button:focus {
  outline: none;
}

@media print {
  .breadcrumb {
    display: none !important;
  }
}

.shadow-hover-card {
  transition: box-shadow 0.3s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  &:hover {
    box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.18);
  }
}

// Quick Fix for Form Submission Dropdown
.mat-menu-panel {
  max-width: 320px !important;
}

@import '~@swimlane/ngx-datatable/index.css';
// @import './ngx-datatable-custom-theme.scss';
@import '~@swimlane/ngx-datatable/themes/material.scss';
.datatable-body-row:hover {
  cursor: pointer;
}

@import '~@swimlane/ngx-datatable/assets/icons.css';

@import '~ngx-toastr/toastr-bs4-alert';

/* Add application styles & imports to this file! */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import '~@angular/material/theming';

@import './custom-material-theme.scss';

.datatable-checkbox input[type='checkbox']::before {
  border: 2px solid #8f8f9d;
}

@import '../app/components/project/dashboard/dashboard.component.scss';
@import '../app/components/project/project-settings/guest-field-definition-settings/guest-field-definition-settings.component.scss';
@import '../app/components/elements/guest-info/guest-info.component.scss';
@import '../app/components/project/sales/sales-dashboard/sales-dashboard.component';

.mat-xs-mini-fab {
  width: 30px !important;
  height: 30px !important;

  .mat-button-wrapper {
    padding: 0 !important;
    line-height: 18px !important;

    .material-icons {
      font-size: 18px !important;
    }
  }
}

@import '../app/components/elements/sales-progress-bar/sales-progress-bar.component';
@import '../app/components/project/guest-details/guest-details.component';
