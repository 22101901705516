@font-face {
  font-family: 'Heebo';
  src: url('../assets/fonts/Heebo-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/Heebo-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/Heebo-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Heebo';
  src: url('../assets/fonts/Heebo-Thin.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/Heebo-Thin.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/Heebo-Thin.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Heebo';
  src: url('../assets/fonts/Heebo-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/Heebo-Light.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/Heebo-Light.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Heebo';
  src: url('../assets/fonts/Heebo-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/Heebo-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/Heebo-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/material-icons.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
