.rtl {
  .sidebar {
    left: auto !important;
    right: 0 !important;

    > ul.list-group {
      padding: 0;
    }
  }

  .main-container {
    margin-left: 0 !important;
    margin-right: 235px;
  }

  /*rtl dropdown items correction*/
  .dropdown-menu {
    text-align: right;
  }

  * {
    direction: rtl;
  }

  .navbar * {
    direction: ltr;
  }

  .sidebar * {
    direction: ltr;
  }

  .navbar .dropdown-menu {
    text-align: left;
  }

  .breadcrumb {
    direction: ltr;
    justify-content: flex-end;

    * {
      direction: ltr;
    }
  }

  .datepicker-input {
    direction: ltr;

    .dropdown-menu {
      direction: ltr;

      * {
        direction: ltr;
      }
    }
  }

  .input-group {
    direction: ltr;
  }
}

@media screen and (max-width: 992px) {
  .rtl {
    .navbar-brand {
      direction: ltr;
    }

    .sidebar {
      right: -235px !important;
    }

    .main-container {
      margin-right: 0;
    }

    &.push-right {
      .sidebar {
        left: auto !important;
        right: 0 !important;
      }
    }
  }
}
